<template>
  <div>
    <div class="mb-1" v-if="idUser">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="timelinePage" />
      </div>
    </div>
    <b-card>
      <b-tabs pills>
        <b-tab active>
          <template #title>
            <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Thông tin nhân viên</span>
          </template>
          <b-card-text class="custom-title">
            <h2>{{isUpdate? 'Cập nhật' : 'Thêm mới'}} nhân viên</h2>
          </b-card-text>
          <b-card-text class="text-center">
            <h4>Thông tin cá nhân</h4>
          </b-card-text>
          <div class="main-form-information">
            <validation-observer ref="modelEmployee">
              <b-row>
                <b-col lg="8" style="margin-top: auto">
                  <div class="top-modal-add-employee">
                    <b-row v-if="isUpdate">
                      <b-col lg="8">
                        <b-form-group>
                          <label class="font-weight-bold font-size-label-custom">Chức vụ</label>
                          <b-form-input  v-model="modelEmployee.organizationBranchSchoolName" readonly></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="4" v-if="!isUpdate">
                        <validation-provider #default="{ errors }" name="departmentId" rules="required">
                          <b-form-group :class="errors.length > 0 ? 'is-invalid':null" >
                            <label class="font-weight-bold font-size-label-custom">Phòng ban <span class="text-danger">(*)</span></label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Chọn phòng ban" label="organizationBranchName" :options="listDepartment" @input="getListDepartmentChildren" :reduce="(department) => department.id" v-model="modelEmployee.departmentId"></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col lg="4">
                        <b-form-group :class="isValidatePartid? 'is-invalid' : null" v-if="listDepartmentChildren.length > 0 && modelEmployee.departmentId">
                          <label class="font-weight-bold font-size-label-custom">Khối <span class="text-danger">(*)</span></label>
                          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="organizationBranchName" placeholder="Chọn khối" :options="listDepartmentChildren" @input="getListDepartmentChildrenPart" :reduce="(part) => part.id" v-model="modelEmployee.partsId">
                      <span slot="no-options" @click="$refs.select.open = false">
                          Chọn phòng ban để lấy khối
                      </span>
                          </v-select>
                          <small v-if="isValidatePartid === true" class="text-danger">Khối không được để trống</small>
                        </b-form-group>
                      </b-col>
                      <b-col lg="4">
                        <b-form-group :class="isValidateUnitId? 'is-invalid' : null" v-if="listUnitDepartmentChildren.length > 0 && modelEmployee.partsId">
                          <label class="font-weight-bold font-size-label-custom">Tổ <span class="text-danger">(*)</span></label>
                          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="organizationBranchName" @input="checkUnit" placeholder="Chọn tổ" :options="listUnitDepartmentChildren" :reduce="(unit) => unit.id" v-model="modelEmployee.unitId">
                       <span slot="no-options" @click="$refs.select.open = false">
                          Chọn Khối để lấy tổ
                      </span>
                          </v-select>
                          <small v-if="isValidateUnitId === true" class="text-danger">Tổ không được để trống</small>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="4" v-if="!isUpdate">
                        <validation-provider #default="{ errors }" name="codeEmployee" rules="required">
                          <b-form-group>
                            <label class="font-weight-bold font-size-label-custom">Mã nhân viên <span class="text-danger">(*)</span></label>
                            <b-form-input style="text-transform: uppercase" :class="errors.length > 0 ? 'is-invalid':null" id="maNVi" v-model="modelEmployee.employeeCode" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col lg="4" v-if="isUpdate">
                        <b-form-group>
                          <label class="font-weight-bold font-size-label-custom">Mã nhân viên <span class="text-danger">(*)</span></label>
                          <b-form-input style="text-transform: uppercase" id="maNVi2" v-model="modelEmployee.employeeCode" readonly/>
                        </b-form-group>
                      </b-col>
                      <b-col lg="4">
                        <validation-provider #default="{ errors }" name="activeStatus" rules="required">
                          <b-form-group :class="errors.length > 0 ? 'is-invalid':null">
                            <label class="font-weight-bold font-size-label-custom">Trạng thái <span class="text-danger">(*)</span></label>
                            <v-select v-model="modelEmployee.activeStatus" :reduce="status => status.value" id="trang-thai-update" :options="listStatusWork" label="text" placeholder="Chọn trạng thái"></v-select>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="12">
                        <div v-if="modelEmployee.activeStatus == 2" class="reason-leave">
                          <b-form-group>
                            <label class="font-weight-bold font-size-label-custom">Lý do</label>
                            <b-form-textarea v-model="modelEmployee.reasons" id="reason" placeholder="Nhập lý do tạm dừng công việc"></b-form-textarea>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col lg="4">
                  <div class="choice-image-employee">
                    <div class="d-flex justify-content-center">
                      <div class="images-employee span-custom mb-2">
                        <span class="font-weight-bold font-size-label-custom">*Ảnh nhân viên</span>
                        <b-img class="mt-1" v-if="imgSrc.images != ''" :src="imgSrc.images" fluid />
                        <b-img class="mt-1" v-if="imgSrc.images == ''" :src="require('@/assets/images/avatars/avatar.jpg')" fluid />
                      </div>
                      <div class="acction-image pl-2">
                        <b-button v-if="modelEmployee.imageUrl !== '' && imageCallApiL.fid !== '' " @click="deleteImage" v-b-tooltip.hover.top="'Xóa ảnh'" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-danger"
                                  class="btn-icon rounded-circle mr-2 customButtonDelete"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                        <b-button v-if="isUpdate && modelEmployee.imageUrl === ''" @click="$refs.refInputEl.click()" v-b-tooltip.hover.top="'Chọn ảnh'" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-primary"
                                  class="btn-icon rounded-circle mr-2 customButtonDelete"
                        >
                          <input accept="image/png, image/gif, image/jpeg" ref="refInputEl" type="file" class="d-none" @change="preview"
                          >
                          <feather-icon icon="ImageIcon" />
                        </b-button>
                        <b-button v-if="imageCallApiL.fid === '' && !isUpdate" @click="$refs.refInputEl.click()" v-b-tooltip.hover.top="'Chọn ảnh'" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-primary"
                                  class="btn-icon rounded-circle mr-2 customButtonDelete"
                        >
                          <input accept="image/png, image/gif, image/jpeg" ref="refInputEl" type="file" class="d-none" @change="preview"
                          >
                          <feather-icon icon="ImageIcon" />
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3">
                  <div class="first-name">
                    <validation-provider #default="{ errors }" name="firstName" rules="required">
                      <b-form-group>
                        <label class="font-weight-bold font-size-label-custom">Họ <span class="text-danger">(*)</span></label>
                        <b-form-input class="text-input-form" :class="errors.length > 0 ? 'is-invalid':null" @input="addFullName" v-model="modelEmployee.firstName" placeholder="Nhập họ"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="nhap-ten">
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Tên <span class="text-danger">(*)</span></label>
                      <validation-provider #default="{ errors }" name="lastName" rules="required">
                        <b-form-input class="text-input-form" @input="addFullName" :class="errors.length > 0 ? 'is-invalid':null" v-model="modelEmployee.lastName" placeholder="Nhập tên"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="birthday">
                    <validation-provider #default="{ errors }" name="birthday" rules="required">
                      <b-form-group :class="errors.length > 0 ? 'is-invalid-datepicker':null">
                        <label class="font-weight-bold font-size-label-custom">Ngày sinh <span class="text-danger">(*)</span></label>
                        <date-time-input  placeholder="Chọn ngày sinh" v-model="modelEmployee.birthday" :config="configFlatpickra"></date-time-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="cmnd">
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Số CMND/Căn cước công dân <span class="text-danger">(*)</span></label>
                      <validation-provider #default="{ errors }" name="identityCard" rules="required">
                        <b-form-input :class="errors.length > 0 ? 'is-invalid':null" v-model="modelEmployee.identityCard" placeholder="Nhập số chứng minh nhân dân hoăc căn cước công dân" type="number"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3">
                  <div class="number-phone">
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Số điện thoại <span class="text-danger">(*)</span></label>
                      <validation-provider #default="{ errors }" name="phoneNumber" rules="required|integer|max:11">
                        <b-form-input :class="errors.length > 0 ? 'is-invalid':null" v-model="modelEmployee.phoneNumber" id="phoneNumber" placeholder="Nhập số điện thoại"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="email-employee">
                    <validation-provider #default="{ errors }" name="email" rules="email">
                      <b-form-group>
                        <label class="font-weight-bold font-size-label-custom">Email</label>
                        <b-form-input  type="email" :class="errors.length > 0 ? 'is-invalid':null" v-model="modelEmployee.email" id="eamilNV" placeholder="Nhập email"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="gender-employee">
                    <validation-provider #default="{ errors }" name="gender" rules="required">
                      <b-form-group :class="errors.length > 0 ? 'is-invalid':null">
                        <label class="font-weight-bold font-size-label-custom">Giới tính<span class="text-danger">(*)</span></label>
                        <v-select id="gioitinhId" v-model="modelEmployee.gender"
                                  label="name"
                                  :options="listGender"
                                  :reduce="gender => gender.value"
                                  placeholder="Chọn giới tính">
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="date-start-work">
                    <validation-provider #default="{ errors }" name="workStartDate" rules="required">
                      <b-form-group :class="errors.length > 0 ? 'is-invalid-datepicker':null">
                        <label class="font-weight-bold font-size-label-custom">Ngày vào làm <span class="text-danger">(*)</span></label>
                        <date-time-input :config="configFlatpickr2" placeholder="Chọn ngày vào làm" v-model="modelEmployee.workStartDate"></date-time-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <!--          <b-col lg="3">-->
                <!--            <div class="work-time">-->
                <!--              <b-form-group label="Thời gian làm việc">-->
                <!--                <b-form-input v-model="modelEmployee.workTime" placeholder="Nhập thời gian làm việc"></b-form-input>-->
                <!--              </b-form-group>-->
                <!--            </div>-->
                <!--          </b-col>-->
                <b-col lg="3">
                  <div class="type-employee">
                    <validation-provider #default="{ errors }" name="type" rules="required">
                      <b-form-group :class="errors.length > 0 ? 'is-invalid':null">
                        <label class="font-weight-bold font-size-label-custom-department">Loại nhân viên <span class="text-danger">(*)</span></label>
                        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="modelEmployee.type" id="selectNhanVienId" :options="listTypeEmployee" label="title" placeholder="Chọn loại nhân viên" :reduce="type => type.value"></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="salary-employee">
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Lương</label>
                      <currency-input v-model="modelEmployee.salary"></currency-input>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="3">
                  <b-form-group>
                    <label class="font-weight-bold font-size-label-custom">Số sổ BHXH</label>
                    <b-form-input type="text" placeholder="Nhập bảo hiểm" v-model="modelEmployee.insurance"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <validation-provider #default="{ errors }" name="houseNumber" rules="required">
                    <b-form-group >
                      <label class="font-weight-bold font-size-label-custom">Số nhà <span class="text-danger">(*)</span></label>
                      <b-form-input :class="errors.length > 0 ? 'is-invalid':null" @input="addAddressEmployee" placeholder="Nhập số nhà" v-model="modelEmployee.houseNumber"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3">
                  <div class="city-address">
                    <validation-provider #default="{ errors }" name="city" rules="required">
                      <b-form-group :class="errors.length > 0 ? 'is-invalid':null">
                        <label class="font-weight-bold font-size-label-custom">Tỉnh/thành phố <span class="text-danger">(*)</span></label>
                        <v-select style="font-weight: 0 !important;" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="province" @input="addAddressEmployee" placeholder="Chọn tỉnh/thành phố" :options="cityOptions" label="label">
                    <span slot="no-options" @click="$refs.select.open = false">
                      Không có dữ liệu
                    </span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="city-district">
                    <validation-provider #default="{ errors }" name="district" rules="required">
                      <b-form-group :class="errors.length > 0 ? 'is-invalid':null">
                        <label class="font-weight-bold font-size-label-custom">Quận/huyện <span class="text-danger">(*)</span></label>
                        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"  v-model="district" @input="addAddressEmployee" placeholder="Chọn quận/huyện" :options="districtOptions" label="label">
                    <span slot="no-options" @click="$refs.select.open = false">
                      Không có dữ liệu
                    </span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="city-wars">
                    <validation-provider #default="{ errors }" name="subDistrict" rules="required">
                      <b-form-group :class="errors.length > 0 ? 'is-invalid':null">
                        <label class="font-weight-bold font-size-label-custom">Xã/phường <span class="text-danger">(*)</span></label>
                        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="subDistrict" @input="addAddressEmployee" placeholder="Chọn xã/phường" :options="subDistrictOptions" label="label">
                  <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <div class="address-current">
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Nơi ở hiện tại</label>
                      <b-form-input disabled="" v-model="modelEmployee.fullAddress" ></b-form-input>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <div class="acction-form-input-employee">
                    <b-button v-if="!isUpdate" class="mr-2" variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="addEmployee"
                    >
                      <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                      />
                      <span class="align-middle">Lưu</span>
                    </b-button>
                    <b-button v-if="isUpdate" class="mr-2" variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="updateEmployee"
                    >
                      <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                      />
                      <span class="align-middle">Lưu</span>
                    </b-button>
                    <b-button @click="goBack" variant="outline-warning" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    >
                      <feather-icon
                          icon="CornerDownLeftIcon"
                          class="mr-50"
                      />
                      <span class="align-middle">Quay lại</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </b-tab>
        <b-tab v-if="isUpdate">
          <template #title>
            <feather-icon
                icon="DollarSignIcon"
                size="16"
                class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Quá trình tăng lương</span>
          </template>
          <div class="tab-two-up-salary">
            <b-row class="mt-1">
              <b-col lg="12">
                <div class="tieu-qua-trinh-tang-luong">
                  <h2 style="color: #059862">Quá trình tăng lương</h2>
                </div>
                <div class="add-salary-new mb-1">
                  <b-row>
                    <b-col lg="12">
                      <div class="acction-salary">
                        <b-button variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="getFormAddSalary"
                        >
                          <feather-icon
                              icon="PlusIcon"
                              class="mr-50"
                          />
                          <span class="align-middle">Thêm mới</span>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="bang-du-lieu-tang-luong">
                  <b-table
                      responsive
                      :per-page="perrPage"
                      :current-page="currentPage"
                      :items="listSalaryEmployee"
                      :fields="fields"
                      class="mb-0">
                    <template #cell(index)="data">
                      <span>{{data.index + 1}}</span>
                    </template>
                    <template #cell(hanhdong)="data">
                      <b-button @click="getFormUpdateSalary(data.item.id)" v-b-tooltip.hover.top="'Cập nhật'" variant="flat-primary" class="btn-icon"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button @click="deletSalaryEmployee(data.item)" v-b-tooltip.hover.top="'Xóa'"
                                variant="flat-danger"
                                class="btn-icon"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </template>
                  </b-table>
                  <div class="footer-salar-employee-detail">
                    <b-button size="sm" class="mt-2" @click="goBack"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                    >
                      <span class="align-middle">Quay lại quản lý nhân viên</span>
                    </b-button>
                    <b-pagination :total-rows="rows"
                                  class="float-right mt-2"
                                  v-model="currentPage"
                                  :per-page="perrPage"
                                  hide-goto-end-buttons></b-pagination>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal
        ref="modal-Salary"
        size="lg"
        hide-footer
        hide-header
        ok-only
    >
      <div class="header-modal-salary">
        <h3>{{isSalary? 'Cập nhật': 'Thêm mới'}} quá trình tăng lương </h3>
      </div>
      <div class="main-form-salary mt-2">
        <validation-observer ref="modelSalaryEmployee">
          <b-row>
            <b-col lg="4">
              <validation-provider #default="{ errors }" name="Bắt đầu năm học" rules="required">
                <b-form-group>
                  <label style="font-size: inherit">Bắt đầu năm học <span class="text-danger">(*)</span></label>
                  <v-select class="custom-v-select" menu-props="auto" @input="onChangeYear" v-model="modelSalaryEmployee.fromYear" label="text" :options="lstYear" :reduce="year => year.value"></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Kết thúc năm học">
                <b-form-input readonly type="text" v-model="yearEnd"></b-form-input>
                <span v-if="isYear" class="text-danger">Năm học kết thúc phải lớn hơn năm học bắt đầu <span class="text-danger">(*)</span></span>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <validation-provider #default="{ errors }" name="Ngày quyết định" rules="required">
                <b-form-group>
                  <label style="font-size: inherit">Ngày quyết định <span class="text-danger">(*)</span></label>
                  <flat-pickr
                      class="form-control"
                      :config="configFlatpickr"
                      v-model="modelSalaryEmployee.startDate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group label="Lương">
                <b-form-input type="text" @keyup="formatSalaryChange(modelSalaryEmployee.salary,1)" v-model="modelSalaryEmployee.salary"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Phụ cấp">
                <b-form-input type="text" @keyup="formatSalaryChange(modelSalaryEmployee.subsidize, 2)" v-model="modelSalaryEmployee.subsidize"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Tổng thu nhập">
                <b-form-input type="text" readonly v-model="totalSalary"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <label style="font-size: inherit">Loại tăng lương <span class="text-danger">(*)</span> </label>
                <v-select v-model="modelSalaryEmployee.type" :options="listTypeUpSalary" label="name" :reduce="type => type.value"></v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <b-form-group label="Lý do tăng lương" v-if="modelSalaryEmployee.type === 1">
                <b-form-input v-model="modelSalaryEmployee.reasons" placeholder="Nhập lý do tăng lương"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
      <div class="footer-modal-salary mt-1">
        <b-row>
          <b-col lg="12">
            <div class="acction-modal-salary">
              <b-button v-if="!isSalary" @click="addSalaryEmployee" class="mr-1" variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              >
                <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tạo mới</span>
              </b-button>
              <b-button v-if="isSalary" @click="updateSalaryEmployee" class="mr-1" variant="outline-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              >
                <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                />
                <span class="align-middle">Cập nhật</span>
              </b-button>
              <b-button variant="outline-danger" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="closeModal"
              >
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Đóng</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, max } from '@validations'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import {
  BCard, BRow, BCol, BFormInput, BCardText, BImg, BButton, VBTooltip, BFormTextarea, BFormGroup, BTab, BTabs, BTable, BModal, BPagination, BBreadcrumb,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import province from '@/assets/utils/province'
import useJwt from '@/auth/jwt/useJwt'
import CurrencyInput from '@core/components/currency-input/CurrencyInput.vue'
import DateTimeInput from '@core/components/date-time-input/DateTimeInput.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    DateTimeInput,
    CurrencyInput,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BCardText,
    BImg,
    BButton,
    vSelect,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BTabs,
    BTable,
    BModal,
    BPagination,
    flatPickr,
    BBreadcrumb,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      currentPage: 1,
      perrPage: 10,
      lstYear: [],
      timelinePage: [],
      modelSalaryEmployee: {
        fromYear: null,
        toYear: null,
        salary: '',
        startDate: '',
        subsidize: '',
        reasons: '',
        type: 0,
        total: '',
      },
      isYear: false,
      listSalaryEmployee: [],
      isValidatePartid: false,
      isValidateUnitId: false,
      isClickSelect: false,
      imageCallApiL: {
        etag: '',
        fid: '',
        name: '',
        publicUrl: '',
        size: '',
        urlImage: '',
      },
      configFlatpickra: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
        maxDate: Date.now(),
      },
      configFlatpickr2: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
      tokenAcccess: null,
      idUser: null,
      textTile: '',
      imgSrc: {
        images: '',
      },
      isImage: false,
      required,
      min,
      max,
      listDepartment: [],
      photo: '',
      fileImage: '',
      listDepartmentChildren: [],
      listUnitDepartmentChildren: [],
      idDetailSalary: '',
      modelEmployee: {
        fullName: '',
        lastName: '',
        activeStatus: 1,
        birthday: '',
        departmentId: null,
        partsId: null,
        city: '',
        district: '',
        subDistrict: '',
        organizationBranchSchoolId: null,
        unitId: '',
        email: '',
        employeeCode: '',
        firstName: '',
        fullAddress: '',
        gender: '',
        houseNumber: '',
        phoneNumber: null,
        salary: 0,
        type: null,
        workStartDate: '',
        insurance: '',
        workTime: '',
        identityCard: null,
        imageUrl: '',
        reasons: '',
      },
      textEmployee: '',
      listTypeUpSalary: [
        {
          id: 1,
          name: 'Cố định',
          value: 0,
        },
        {
          id: 2,
          name: 'Đặc biệt',
          value: 1,
        },
      ],
      cityOptions: province.getProvince(),
      districtOptions: province.getDistrict(),
      subDistrictOptions: province.getSubDistrict(),
      isSalary: false,
      listStatusWork: [
        {
          id: 1,
          text: 'Đã nghỉ việc',
          value: 0,
        },
        {
          id: 2,
          text: 'Đang làm việc',
          value: 1,
        },
        {
          id: 3,
          text: 'Tạm dừng công việc',
          value: 2,
        },

      ],
      listGender: [
        {
          id: 1,
          name: 'Khác',
          value: 0,
        },
        {
          id: 2,
          name: 'Nam',
          value: 1,
        },
        {
          id: 3,
          name: 'Nữ',
          value: 2,
        },
      ],
      listTypeEmployee: [
        {
          id: 1,
          title: 'Chưa xác định',
          value: 0,
        },
        {
          id: 2,
          title: 'Thử việc',
          value: 1,
        },
        {
          id: 3,
          title: 'Chính thức',
          value: 2,
        },
      ],
      salary: '',
      subSalary: '',
      yearEnd: null,
      totalSalary: '',
      fields: [
        {
          key: 'index', label: 'stt',
        },
        {
          key: 'fromYear', label: 'Bắt đầu năm học',
        },
        {
          key: 'toYear', label: 'Năm học kết thúc',
        },
        {
          key: 'salary', label: 'Lương',
        },
        {
          key: 'subsidize', label: 'Phụ cấp',
        },
        {
          key: 'total', label: 'Tổng thu nhập',
        },
        {
          key: 'hanhdong', label: 'Hành động',
        },
      ],
      isUpdate: false,
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
    }
  },
  created() {
    const now = new Date().getFullYear()
    const parameters = this.$route.query
    this.textEmployee = parameters.name
    this.timelinePage = [
      {
        text: 'Nhân viên',
        href: '/manager-employee',
      },
      {
        text: `${this.textEmployee}`,
      },
    ]
    if (parameters.id) {
      this.isUpdate = true
      this.idUser = parameters.id
      this.getDetailEmployee()
      this.getListSalaryEmployee()
    }
    for (let i = 1998; i <= now; i++) {
      this.lstYear.push({ value: i, text: `${i}` })
    }
    this.getListAllDepartment()
  },
  computed: {
    rows() {
      return this.listSalaryEmployee.length
    },
    province: {
      get() {
        const obj = this.cityOptions.find(x => x.value === this.modelEmployee.city)
        if (!obj) {
          return null
        }
        return {
          label: obj.label,
          value: this.modelEmployee.city,
        }
      },
      set(obj) {
        if (obj !== null) {
          this.modelEmployee.city = obj.value
          this.districtOptions = province.getDistrict(obj.value)
          this.modelEmployee.district = this.districtOptions[0].value
          this.subDistrictOptions = province.getSubDistrict(this.modelEmployee.city, this.modelEmployee.district)
          this.modelEmployee.subDistrict = this.subDistrictOptions[0].value
        }
        if (obj === null) {
          this.modelEmployee.city = ''
          this.modelEmployee.district = ''
          this.modelEmployee.subDistrict = ''
          this.modelEmployee.fullAddress = ''
          this.districtOptions = []
        }
      },
    },
    district: {
      get() {
        const obj = this.districtOptions.find(x => x.value === this.modelEmployee.district)
        if (!obj) {
          return null
        }
        return {
          label: obj.label,
          value: this.modelEmployee.district,
        }
      },
      set(obj) {
        if (obj !== null) {
          this.modelEmployee.district = obj.value
          this.subDistrictOptions = province.getSubDistrict(this.modelEmployee.city, obj.value)
          this.modelEmployee.subDistrict = this.subDistrictOptions[0].value
        }
        if (obj === null) {
          this.modelEmployee.district = ''
          this.modelEmployee.subDistrict = ''
          this.subDistrictOptions = []
        }
      },
    },
    subDistrict: {
      get() {
        const obj = this.subDistrictOptions.find(x => x.value === this.modelEmployee.subDistrict)
        if (!obj) {
          return null
        }
        return {
          label: obj.label,
          value: this.modelEmployee.subDistrict,
        }
      },
      set(obj) {
        if (obj !== null) {
          this.modelEmployee.subDistrict = obj.value
        }
        if (obj === null) {
          this.modelEmployee.subDistrict = ''
        }
      },
    },
  },
  methods: {
    goBack() {
      this.$router.push('/manager-employee')
    },
    getListAllDepartment() {
      this.modelEmployee.departmentId = null
      this.modelEmployee.partsId = null
      this.modelEmployee.unitId = null
      this.listDepartmentChildren = []
      this.listUnitDepartmentChildren = []
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    getFormAddSalary() {
      this.isSalary = false
      this.modelSalaryEmployee.salary = ''
      this.modelSalaryEmployee.total = ''
      this.modelSalaryEmployee.subsidize = ''
      this.modelSalaryEmployee.fromYear = null
      this.modelSalaryEmployee.toYear = null
      this.modelSalaryEmployee.startDate = ''
      this.modelEmployee.reasons = ''
      this.modelSalaryEmployee.type = 0
      this.totalSalary = ''
      this.yearEnd = null
      this.$refs['modal-Salary'].show()
    },
    closeModal() {
      this.$refs['modal-Salary'].hide()
    },
    getListSalaryEmployee() {
      this.$crm.get(`salary-school/find-by-id-employeeSchoolId/${this.idUser}`).then(res => {
        this.listSalaryEmployee = res.data
        for (let i = 0; i < this.listSalaryEmployee.length; i++) {
          this.listSalaryEmployee[i].salary = new Intl.NumberFormat().format(this.listSalaryEmployee[i].salary).toString()
          this.listSalaryEmployee[i].subsidize = new Intl.NumberFormat().format(this.listSalaryEmployee[i].subsidize).toString()
          this.listSalaryEmployee[i].total = new Intl.NumberFormat().format(this.listSalaryEmployee[i].total).toString()
        }
      })
    },
    getListDepartmentChildren(id) {
      this.isClickSelect = false
      this.listUnitDepartmentChildren = []
      this.modelEmployee.partsId = null
      if (this.modelEmployee.departmentId && this.modelEmployee.partsId === null) {
        this.isValidatePartid = true
      }
      this.modelEmployee.organizationBranchSchoolId = id
      if (id !== null) {
        this.$crm.get(`organization-branch-school/find-children/${id}`).then(res => {
          this.listDepartmentChildren = res.data
          this.listUnitDepartmentChildren = []
          this.getListDepartmentChildrenPart(this.modelEmployee.partsId)
        })
      }
    },
    getListDepartmentChildrenPart(id) {
      this.isClickSelect = false
      this.modelEmployee.unitId = null
      this.modelEmployee.organizationBranchSchoolId = id
      if (this.modelEmployee.departmentId && this.modelEmployee.partsId) {
        this.isValidatePartid = false
      }
      if (this.modelEmployee.departmentId && this.modelEmployee.partsId === null) {
        this.isValidatePartid = true
      }
      if (this.modelEmployee.partsId && this.modelEmployee.departmentId && this.modelEmployee.unitId === null) {
        this.isValidateUnitId = true
      }
      if (id) {
        this.$crm.get(`organization-branch-school/find-children/${id}`).then(res => {
          this.listUnitDepartmentChildren = res.data
        })
      }
    },
    checkUnit(id) {
      this.isClickSelect = true
      if (this.modelEmployee.partsId && this.modelEmployee.departmentId && this.modelEmployee.unitId === null) {
        this.isValidateUnitId = true
      }
      if (this.modelEmployee.partsId && this.modelEmployee.departmentId && this.modelEmployee.unitId) {
        this.modelEmployee.organizationBranchSchoolId = id
        this.isValidateUnitId = false
      }
    },
    addFullName() {
      if (this.modelEmployee.firstName) {
        this.modelEmployee.fullName = this.modelEmployee.firstName
      }
      if (this.modelEmployee.lastName) {
        this.modelEmployee.fullname = this.modelEmployee.lastName
      }
      if (this.modelEmployee.fullName && this.modelEmployee.lastName) {
        this.modelEmployee.fullName += ` ${this.modelEmployee.lastName}`
      }
    },
    addAddressEmployee() {
      const city = this.cityOptions.find(x => x.value === this.modelEmployee.city)
      let districtModel = ''
      let subDistrictModel = ''
      if (city) {
        this.modelEmployee.fullAddress = city.label
      }
      if (city) {
        districtModel = this.districtOptions.find(x => x.value === this.modelEmployee.district)
      }
      if (districtModel) {
        subDistrictModel = this.subDistrictOptions.find(x => x.value === this.modelEmployee.subDistrict)
      }
      if (districtModel) {
        this.modelEmployee.fullAddress = districtModel.label
      }
      if (districtModel && city) {
        this.modelEmployee.fullAddress += `, ${city.label}`
      }
      if (subDistrictModel) {
        this.modelEmployee.fullAddress = subDistrictModel.label
      }
      if (subDistrictModel && districtModel) {
        this.modelEmployee.fullAddress += `, ${districtModel.label}`
      }
      if (this.modelEmployee.houseNumber) {
        this.modelEmployee.fullAddress = this.modelEmployee.houseNumber
      }
      if (this.modelEmployee.houseNumber && subDistrictModel) {
        this.modelEmployee.fullAddress += `, ${subDistrictModel.label}`
      }
      if (subDistrictModel && this.modelEmployee.houseNumber === '') {
        this.modelEmployee.fullAddress += `, ${city.label}`
      }
      if (this.modelEmployee.houseNumber && subDistrictModel && districtModel) {
        this.modelEmployee.fullAddress += `, ${districtModel.label}`
      }
      if (this.modelEmployee.houseNumber && subDistrictModel && districtModel && city) {
        this.modelEmployee.fullAddress += `, ${city.label}`
      }
    },
    addEmployee() {
      if (this.modelEmployee.employeeCode) {
        this.modelEmployee.employeeCode = this.modelEmployee.employeeCode.toUpperCase()
      }
      if (this.modelEmployee.fullName) {
        const arr = this.modelEmployee.fullName.split(' ')
        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }
        const str2 = arr.join(' ')
        this.modelEmployee.fullName = str2
      }
      if (this.modelEmployee.departmentId && this.modelEmployee.partsId === null) {
        this.isValidatePartid = true
        return
      }
      if (this.modelEmployee.departmentId && this.modelEmployee.partsId && this.modelEmployee.unitId === null && this.isClickSelect === true) {
        this.isValidateUnitId = true
        return
      }
      this.$refs.modelEmployee.validate().then(success => {
        if (success) {
          if (this.isImage === true) {
            this.$crm.post('employee-school/avatar/upload', this.imgSrc).then(res => {
              this.imageCallApiL = res.data
              const totalImageUrl = this.imageCallApiL.publicUrl += this.imageCallApiL.fid
              this.modelEmployee.imageUrl = totalImageUrl
              this.isImage = false
              this.callAddEmployee()
            })
          } else {
            this.callAddEmployee()
          }
        }
      })
    },
    callAddEmployee() {
      this.$crm.post('employee-school/create', [this.modelEmployee]).then(res => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thành công!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Thêm mới nhân viên thành công',
          },
        })
        this.$router.push({
          name: 'manager-employee',
        })
      })
    },
    getDetailEmployee() {
      this.$crm.get(`employee-school/find-by-id/${this.idUser}`).then(res => {
        this.modelEmployee = res.data
        if (this.modelEmployee.imageUrl !== undefined) {
          this.imgSrc.images = this.modelEmployee.imageUrl
        }
        if (this.imageCallApiL.fid === '' && this.modelEmployee.imageUrl !== undefined) {
          const spiltString = this.modelEmployee.imageUrl.split('/')
          this.imageCallApiL.fid = spiltString[3]
        }
        this.districtOptions = province.getDistrict(this.modelEmployee.city)
        this.subDistrictOptions = province.getSubDistrict(this.modelEmployee.city, this.modelEmployee.district)
      })
    },
    updateEmployee() {
      if (this.modelEmployee.employeeCode) {
        this.modelEmployee.employeeCode = this.modelEmployee.employeeCode.toUpperCase()
      }
      if (this.modelEmployee.fullName) {
        const arr = this.modelEmployee.fullName.split(' ')
        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }
        const str2 = arr.join(' ')
        this.modelEmployee.fullName = str2
      }
      this.$refs.modelEmployee.validate().then(success => {
        if (success) {
          if (this.isImage === true && this.imgSrc.images !== '') {
            this.$crm.post('employee-school/avatar/upload', this.imgSrc).then(res => {
              this.imageCallApiL = res.data
              const employeeImageUrl = this.imageCallApiL.publicUrl += this.imageCallApiL.fid
              this.modelEmployee.imageUrl = employeeImageUrl
              this.updateEmployeeWithImage()
              this.isImage = false
            })
          } else {
            this.updateEmployeeWithImage()
          }
        }
      })
    },
    updateEmployeeWithImage() {
      this.$crm.post(`employee-school/update/${this.idUser}`, this.modelEmployee).then(res => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thành công!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Cập nhật nhân viên thành công',
          },
        })
        this.$router.push({
          name: 'manager-employee',
        })
      })
    },
    preview(e) {
      const sizeInBytes = e.target.files[0].size
      const file = e.target.files[0]
      if (sizeInBytes > 1000141) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi tải file',
            icon: 'XIcon',
            text: 'Ảnh tải lên không được vượt quá 1Mb',
            variant: 'danger',
          },
        })
        this.imgSrc.images = ''
        return
      }
      const imgSrc = URL.createObjectURL(e.target.files[0])
      this.imgSrc.images = imgSrc
      this.convertFileBase64(file)
      this.isImage = true
    },
    convertFileBase64(file) {
      const reader = new FileReader()
      if (file) {
        reader.readAsDataURL(file)
        reader.onload = () => {
          const base64 = reader.result
          this.imgSrc.images = base64
        }
      }
    },
    deleteImage() {
      const urlDeleteImage = this.imageCallApiL.fid
      this.$crm.post(`employee-school/avatar/delete?fId=${urlDeleteImage}&employeeSchoolId=${this.idUser}`).then(res => {
        this.imgSrc.images = ''
        this.imageCallApiL.fid = ''
        this.getDetailEmployee()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            showConfirmButton: false,
            title: 'Thành công',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Xóa ảnh thành công',
          },
        })
      })
    },
    updateSalaryEmployee() {
      this.$refs.modelSalaryEmployee.validate().then(success => {
        if (success) {
          if (this.modelSalaryEmployee.salary) {
            this.modelSalaryEmployee.salary = parseFloat(this.modelSalaryEmployee.salary.replace(/[^0-9]/gi, ''))
          }
          if (this.modelSalaryEmployee.subsidize) {
            this.modelSalaryEmployee.subsidize = parseFloat(this.modelSalaryEmployee.subsidize.replace(/[^0-9]/gi, ''))
          }
          if (this.totalSalary) {
            this.modelSalaryEmployee.total = parseFloat(this.totalSalary.replace(/[^0-9]/gi, ''))
          }
          this.$crm.post(`salary-school/update/${this.idDetailSalary}`, this.modelSalaryEmployee).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Cập nhật thành công',
              },
            })
            this.getListSalaryEmployee()
            this.$refs['modal-Salary'].hide()
          }, err => {
            this.modelSalaryEmployee.salary = new Intl.NumberFormat().format(this.modelSalaryEmployee.salary).toString()
            this.modelSalaryEmployee.subsidize = new Intl.NumberFormat().format(this.modelSalaryEmployee.subsidize).toString()
            this.modelSalaryEmployee.total = new Intl.NumberFormat().format(this.modelSalaryEmployee.total).toString()
            this.totalSalary = this.modelSalaryEmployee.total
          })
        }
      })
    },
    getFormUpdateSalary(id) {
      this.idDetailSalary = id
      this.$crm.get(`salary-school/find-by-id/${id}`).then(res => {
        this.modelSalaryEmployee = res.data
        this.totalSalary = this.modelSalaryEmployee.total
        if (this.modelSalaryEmployee.toYear) {
          this.yearEnd = this.modelSalaryEmployee.toYear
        }
        if (this.modelSalaryEmployee.salary) {
          this.modelSalaryEmployee.salary = new Intl.NumberFormat().format(this.modelSalaryEmployee.salary).toString()
        }
        if (this.modelSalaryEmployee.subsidize) {
          this.modelSalaryEmployee.subsidize = new Intl.NumberFormat().format(this.modelSalaryEmployee.subsidize).toString()
        }
        if (this.modelSalaryEmployee.total) {
          this.modelSalaryEmployee.total = new Intl.NumberFormat().format(this.modelSalaryEmployee.total).toString()
          this.totalSalary = this.modelSalaryEmployee.total
        }
        this.isSalary = true
        this.$refs['modal-Salary'].show()
      })
    },
    addSalaryEmployee() {
      this.$refs.modelSalaryEmployee.validate().then(success => {
        if (success) {
          if (this.modelSalaryEmployee.salary) {
            this.modelSalaryEmployee.salary = parseFloat(this.modelSalaryEmployee.salary.replace(/[^0-9]/gi, ''))
          }
          if (this.modelSalaryEmployee.subsidize) {
            this.modelSalaryEmployee.subsidize = parseFloat(this.modelSalaryEmployee.subsidize.replace(/[^0-9]/gi, ''))
          }
          if (this.totalSalary) {
            this.modelSalaryEmployee.total = parseFloat(this.totalSalary.replace(/[^0-9]/gi, ''))
          }
          this.$crm.post(`salary-school/create/${this.idUser}`, this.modelSalaryEmployee).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo mới thành công',
              },
            })
            this.getListSalaryEmployee()
            this.$refs['modal-Salary'].hide()
          }, err => {
            this.modelSalaryEmployee.salary = new Intl.NumberFormat().format(this.modelSalaryEmployee.salary).toString()
            this.modelSalaryEmployee.subsidize = new Intl.NumberFormat().format(this.modelSalaryEmployee.subsidize).toString()
            this.modelSalaryEmployee.total = new Intl.NumberFormat().format(this.modelSalaryEmployee.total).toString()
          })
        }
      })
    },
    onChangeYear(year) {
      if (year) {
        this.modelSalaryEmployee.toYear = year + 1
        this.yearEnd = this.modelSalaryEmployee.toYear
      } else {
        this.yearEnd = null
      }
    },
    formatSalaryChange(value, type) {
      const tg = value.replace(/[^0-9]/gi, '')
      const nb = parseFloat(tg === '' ? '0' : tg)
      const fn = new Intl.NumberFormat().format(nb)
      if (this.modelSalaryEmployee.salary === '' && this.modelSalaryEmployee.subsidize !== '') {
        this.modelSalaryEmployee.salary = 0
        this.totalSalary = this.modelSalaryEmployee.subsidize
        return
      }
      if (this.modelSalaryEmployee.subsidize === '' && this.modelSalaryEmployee.salary !== '') {
        this.modelSalaryEmployee.subsidize = 0
        this.totalSalary = this.modelSalaryEmployee.salary
        return
      }
      if (this.modelSalaryEmployee.salary !== '' && type === 1) {
        this.modelSalaryEmployee.salary = fn
        this.modelSalaryEmployee.total = fn
        this.totalSalary = this.modelSalaryEmployee.total
      }
      if (this.modelSalaryEmployee.subsidize !== '' && type === 2) {
        this.modelSalaryEmployee.subsidize = fn
        this.modelSalaryEmployee.total = fn
        this.totalSalary = this.modelSalaryEmployee.total
      }
      if (this.modelSalaryEmployee.salary !== 0 && this.modelSalaryEmployee.salary !== undefined) {
        this.salary = parseFloat(this.modelSalaryEmployee.salary.replace(/[^0-9]/gi, ''))
      }
      if (this.modelSalaryEmployee.subsidize !== 0 && this.modelSalaryEmployee.subsidize !== undefined) {
        this.subSalary = parseFloat(this.modelSalaryEmployee.subsidize.replace(/[^0-9]/gi, ''))
      }
      if (this.salary !== '' && this.subSalary !== '') {
        const nbTotal = this.salary + this.subSalary
        this.modelSalaryEmployee.total = new Intl.NumberFormat().format(nbTotal).toString()
        this.totalSalary = new Intl.NumberFormat().format(nbTotal).toString()
      }
    },
    deletSalaryEmployee(item) {
      this.$swal({
        title: 'Xóa nâng lương',
        text: `Bạn có muốn xóa nâng lương ${item.fromYear} - ${item.toYear}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`salary-school/delete/${item.id}`).then(res => {
            this.getListSalaryEmployee()
            this.$swal({
              icon: 'success',
              title: 'Xóa thành công',
              text: `Xóa thành công nâng lương ${item.fromYear} - ${item.toYear}`,
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/manger-employee';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
@import 'src/@core/scss/vue/pages/page-auth.scss';
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
