<template>
  <div>
    <flat-pickr
      v-model="displayValue"
      class="form-control"
      :config="config"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  name: 'DateTimeInput',
  components: {
    flatPickr,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isInputActive: false,
    }
  },
  computed: {
    displayValue: {
      get() {
        return this.value
      },
      set(modifiedValue) {
        this.$emit('input', moment(modifiedValue).format('yyyy-MM-DDT00:00:00'))
      },
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
