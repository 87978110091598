<template>
  <div>
    <b-form-input type="text" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true">
    </b-form-input>
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'CurrencyInput',
  components: {
    BFormInput,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isInputActive: false,
    }
  },
  computed: {
    displayValue: {
      get() {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value.toString()
        }

        const value = this.value.toString().replace(/\D/g, '')
        const nf = Intl.NumberFormat()
        return nf.format(value)
      },
      set(modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ''))
        // Ensure that it is not NaN
        if (Number.isNaN(newValue)) {
          newValue = 0
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit('input', newValue)
      },
    },
  },
}
</script>

<style scoped>

</style>
